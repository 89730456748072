import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Button, Tooltip } from 'reactstrap';
import RouteLabel from '../components/RouteLabel';
import Table from '../components/Table';
import axios from 'axios';
import { BASE_URL, getUserData, logout } from '../utils';
import LoadingBar from 'react-top-loading-bar';
import { Plus } from 'react-feather';
import { Link, useNavigate } from 'react-router-dom';
import LoadingComponent from '../components/LoadingComponent';
import ErrorComponent from '../components/ErrorComponent';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ModalAlert from '../components/ModalAlert';
import { handleClearInput } from '../redux/actions/Table';
import { useDispatch, useSelector } from 'react-redux';
import NotFound from '../components/NotFound';
import { useTranslation } from 'react-i18next';
import TableDetailsModal from '../components/TableDetailsModal';

const ClientList = () => {

  const notifyDel = () => toast.success("Deleted Successfully");
  const notifyErr = () => toast.error("Failed to delete partner!");

  const ref = useRef(null);
  const navigate = useNavigate();
  const [partnersList, setPartnersList] = useState([]);
  const [err, setErr] = useState(false);
  const [retry, setRetry] = useState(false)
  const [openAlert, setOpenAlert] = useState(false)
  const [currentPartner, setCurrentPartner] = useState(false);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [currentPurchaseOrder,setCurrentPurchaseOrder] = useState(false)
  const [mobileColumn, setMobileColumn] = useState(false)
  const [t, i18n] = useTranslation("clients");




  const curLang = useSelector(state => state.langReducer)
  useEffect(() => {


    if (window.innerWidth <= 500)
      setMobileColumn([
        {
          Header: t('table.0'),
          accessor: "name",

        },
        {
          Header: t('table.5'),//"Action ",
          Cell: (row) => {
            return (
              <div className='tbl-action-col'>


                <RenderBtn id={"edit" + row.row.id} tooltip="Edit" className='tbl-action-btn' color="btn btn-primary" onClick={() => { navigate("/partners/edit-partner", { state: { userData: { ...row.row.original } } }); }}><i className="fas fa-edit" ></i></RenderBtn>
                <RenderBtn id="create" tooltip="Create Purchase Order" className='tbl-action-btn' color="btn btn-primary" alt="Create Purchase Order" onClick={() => { navigate("/partners/create-purchase-order", { state: { userData: { ...row.row.original } } }); }}><i className="fas fa-plus" ></i></RenderBtn>
                <RenderBtn id={"partner-delete" + row.row.id} tooltip="Delete" className='tbl-action-btn' color="btn btn-primary" onClick={() => { setCurrentPartner(row.row.original); setOpenAlert(true) /*deletePartner(row.row.original)*//*navigate("/session-timed-out");*/ }}><i className="fas fa-trash" ></i></RenderBtn>
                <RenderBtn id={"view" + row.row.id} tooltip={"View Details"} className='tbl-action-btn' color="btn btn-primary" icon={<i className="fas fa-ellipsis-v" ></i>} onClick={() => { setCurrentPartner(row.row.original); setOpenDetailsModal(true) /*setOpenAlert(true); /* deletePurchaseOrder(row.row.original) /*navigate("/session-timed-out");*/ }}><i className="fas fa-ellipsis-v" ></i></RenderBtn>

              </div>
            );
          }
        },
        {
          Header: t('table.1'),
          accessor: "phoneNumber",
          hidden: true
        },
        {
          Header: t('table.2'),
          accessor: "contactPerson",
          hidden: true
        },
        {
          Header: t('table.3'),
          accessor: "email",
          hidden: true
        },
        {
          Header: t('table.4'),
          accessor: "btwNumber",
          hidden: true
        },

      ]
      )


    var winEvent = window.addEventListener('resize', function (event) {
      const currentScreenWidth = event.target.innerWidth;
      if (currentScreenWidth <= 500) {
        setMobileColumn([
          {
            Header: t('table.0'),
            accessor: "name",

          },
          {
            Header: t('table.5'),//"Action ",
            Cell: (row) => {
              return (
                <div className='tbl-action-col'>


                  <RenderBtn id={"edit" + row.row.id} tooltip="Edit" className='tbl-action-btn' color="btn btn-primary" onClick={() => { navigate("/partners/edit-partner", { state: { userData: { ...row.row.original } } }); }}><i className="fas fa-edit" ></i></RenderBtn>
                  <RenderBtn id="create" tooltip="Create Purchase Order" className='tbl-action-btn' color="btn btn-primary" alt="Create Purchase Order" onClick={() => { navigate("/partners/create-purchase-order", { state: { userData: { ...row.row.original } } }); }}><i className="fas fa-plus" ></i></RenderBtn>
                  <RenderBtn id={"partner-delete" + row.row.id} tooltip="Delete" className='tbl-action-btn' color="btn btn-primary" onClick={() => { setCurrentPartner(row.row.original); setOpenAlert(true) /*deletePartner(row.row.original)*//*navigate("/session-timed-out");*/ }}><i className="fas fa-trash" ></i></RenderBtn>
                  <RenderBtn id={"view" + row.row.id} tooltip={"View Details"} className='tbl-action-btn' color="btn btn-primary" icon={<i className="fas fa-ellipsis-v" ></i>} onClick={() => { setCurrentPartner(row.row.original); setOpenDetailsModal(true) /*setOpenAlert(true); /* deletePurchaseOrder(row.row.original) /*navigate("/session-timed-out");*/ }}><i className="fas fa-ellipsis-v" ></i></RenderBtn>

                </div>
              );
            }
          },
          {
            Header: t('table.1'),
            accessor: "phoneNumber",
            hidden: true
          },
          {
            Header: t('table.2'),
            accessor: "contactPerson",
            hidden: true
          },
          {
            Header: t('table.3'),
            accessor: "email",
            hidden: true
          },
          {
            Header: t('table.4'),
            accessor: "btwNumber",
            hidden: true
          },
        ]
        )
      } else {
        setMobileColumn(false)
      }
    })

    return () => window.removeEventListener('resize', winEvent)

  }, [])

  useEffect(() => {
    i18n.changeLanguage(curLang);
  }, [curLang])




  const login = getUserData();
  const dispatch = useDispatch()

  async function getPartners() {
    if (ref.current)
      ref.current.continuousStart()
    setLoading(true)
    try {
      var res = await axios.get(BASE_URL + '/clients', {
        headers: {
          "Content-Type": "application/json",
          "mode": "cors",
          "Authorization": login.accessToken
        }
      });
      if (res.status == 200 && res.data.partners) {

        res.data.partners = res.data.partners.map(val => {
          var obj = { ...val };
          // obj.contactPerson = val.phoneNumber;
          // obj.email = val.contactPerson;
          // obj.phoneNumber = val.email;
          obj.phone = obj.phoneNumber
          val = obj
          return val
        });
        setPartnersList(res.data.partners);
        setErr(false)
      }
    }
    catch (e) {
      setErr(true);
      logout();

      localStorage.setItem("loginDetails", null)
      window.location.reload()

    }
    if (ref.current)
      ref.current.complete()
    setLoading(false)
  }


  useEffect(() => {
    if (err)
      setErr(!err)
    getPartners();
  }, [retry])


  const columns = /*useMemo(
    () =>*/ [

      // {
      //   Header: "P.No",
      //   accessor: "btwNumber"

      // },
      {
        Header: t('table.0'),
        accessor: "name",

      },
      {
        Header: t('table.1'),
        accessor: "phoneNumber"
      },
      {
        Header: t('table.2'),
        accessor: "contactPerson"
      },
      {
        Header: t('table.3'),
        accessor: "email"
      },
      {
        Header: t('table.4'),
        accessor: "btwNumber"
      },
      //     {
      //       Header: "Action ",
      //       Cell: (row) => {

      //         return (
      //           <div style={{ display: "flex" }}>

      //             <Button className='tbl-action-btn' color="btn btn-primary" onClick={()=>{ navigate("/partners/edit-partner",{state:{userData:{...row.row.original}}});}}><i className="fas fa-edit" ></i></Button>
      //             <Button className='tbl-action-btn' color="btn btn-primary" alt="Create Purchase Order" onClick={()=>{ navigate("/partners/create-purchase-order",{state:{userData:{...row.row.original}}});}}><i className="fas fa-plus" ></i></Button>
      //             <Button className='tbl-action-btn' color="btn btn-primary" onClick={()=>{ /*navigate("/session-timed-out");*/}}><i className="fas fa-trash" ></i></Button>
      //           </div>
      //         );
      //       }
      //     }

      //   ]
      //   ,
      //   []
      // );

      {
        Header: t('table.5'),
        Cell: (row) => {


          return (
            <div className='tbl-action-col'>

              <RenderBtn id={"edit" + row.row.id} tooltip="Edit" className='tbl-action-btn' color="btn btn-primary" onClick={() => { navigate("/clients/edit-client", { state: { userData: { ...row.row.original } } }); }}><i className="fas fa-edit" ></i></RenderBtn>
              <RenderBtn id="create" tooltip="Create Purchase Order" className='tbl-action-btn' color="btn btn-primary" alt="Create Purchase Order" onClick={() => { navigate("/partners/create-purchase-order", { state: { userData: { ...row.row.original } } }); }}><i className="fas fa-plus" ></i></RenderBtn>
              <RenderBtn id={"partner-delete" + row.row.id} tooltip="Delete" className='tbl-action-btn' color="btn btn-primary" onClick={() => { setCurrentPartner(row.row.original); setOpenAlert(true) /*deletePartner(row.row.original)*//*navigate("/session-timed-out");*/ }}><i className="fas fa-trash" ></i></RenderBtn>

            </div>
          );
        }
      }

    ]
  //   ,
  //   []
  // );


  async function deletePartner(partner) {
    if (ref.current)
      ref.current.continuousStart()
    try {
      var res = await axios.delete(BASE_URL + `/partners/${partner.partnerId}`, {
        headers: {
          "Content-Type": "application/json",
          "mode": "cors",
          "Authorization": login.accessToken
        }
      })
      if (res.status == 200 || res.status == 204) {
        notifyDel();
        getPartners();
        dispatch(handleClearInput(''));
      }
    }
    catch (e) {
      notifyErr();
    }
    if (ref.current)
      ref.current.complete()
  }

  function RenderBtn({ className, onClick, icon, tooltip, id, children }) {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);
    return (
      <>

        <Button id={id} className={className} color="btn btn-primary" onClick={onClick}>{children}</Button>
        <Tooltip
          isOpen={tooltipOpen}
          toggle={toggle}
          placement="right" target={id} /*toggle={this.toggle}*/>
          {tooltip}
        </Tooltip>
      </>
    )
  }


  return (
    <div>
      <LoadingBar color='#3072bb' ref={ref} height={7} />
      {partnersList.length > 0 ? <div>
        <RouteLabel />
        <h2 className='page-title'>{t("header")}<Link className='add-invoice-link' to='/clients/create-client/' ><Button className='page-btn'  >Create Client <Plus size={20} /></Button></Link></h2>
        <Table data={partnersList} columns={mobileColumn ? mobileColumn : columns} title={t("header")} />
      </div> : err ? <ErrorComponent retry={retry} setRetry={setRetry} /> : loading ? <LoadingComponent /> : <NotFound msg={'No partners found!'} >
        <Link className='add-invoice-link' to='/partners/create-partner/' ><Button className='page-btn'  >Create Partner <Plus size={20} /></Button></Link>
      </NotFound>}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <ModalAlert open={openAlert} setOpen={setOpenAlert} onSuccess={() => { if (currentPartner) { deletePartner(currentPartner); setCurrentPartner(false) } }}>Are you sure to delete this partner</ModalAlert>
      <TableDetailsModal isOpen={openDetailsModal} setIsOpen={setOpenDetailsModal} data={currentPartner} />
    </div>
  )
}

export { ClientList }